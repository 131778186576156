import React, { useState, useRef } from "react"
import MainLayout from "../components/MainLayout"
import { graphql, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { contactForm, recaptcha } from "../styles/contact.module.css"
import { Helmet } from "react-helmet"
import { Axios, firebase } from "../utils/firebase"
import ReCAPTCHA from "react-google-recaptcha";


export default function Contact({ data }) {

  const [formData, setFormData] = useState({})
  const [validCaptcha, setValidCaptcha] = useState(null)
  const captcha = useRef(null);

  const updateInput = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (captcha.current.getValue()) {
      setValidCaptcha(true);
      sendEmail()
      setFormData({
        name: '',
        phone: '',
        email: '',
        message: '',
      })
      navigate("/thanks_newsletter")
    } else {
      setValidCaptcha(false);
    }
  }

  // verified recaptcha 
  const onChange = () => {
    if (captcha.current.getValue()) {
      // valid captcha
      setValidCaptcha(true);
    }
  }

  const sendEmail = () => {
    Axios.post(
      'https://us-central1-linguolab-2a7cc.cloudfunctions.net/submitNewsletter',
      formData
    )
      .then(res => {
        firebase.firestore().collection('newsletter').add({
          name: formData.name,
          email: formData.email,
          createdAt: new Date(),
        })
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <MainLayout>
      <Helmet>
        <title>Linguolab Newsletter</title>
        <meta
          name="description"
          content="Únete a nuestro Newsletter para estar siempre al tanto sobre nuestros cursos, tips, posts, promociones y mucho más"
        ></meta>
        <meta
          name="keywords"
          content="linguolab, newsletter, información, aprender idiomas, aprender, idiomas, cursos, clases, plataforma, online, zoom, virtual, linguo, ingles, inglés, frances, francés, aleman, alemán"
        />
        <html lang="es" />
      </Helmet>
      <div className="page-header">
        <div className="page-header-titles">
          <h2>Newsletter</h2>
        </div>
        <GatsbyImage
          image={data.titleImage.childImageSharp.gatsbyImageData}
          className="page-header-background"
          alt="thumb"
          objectFit="cover"
          objectPosition="25% 50%"
        />
      </div>
      <div className="layout">
        <div className={`page-content`}>
          <div className={`${contactForm}`}>
              <p style={{marginBottom: '30px', textAlign: 'center'}}>¡Completa le formulario para unirte a nuestro Newsletter!</p>
            <form
              onSubmit={handleSubmit}
              name="contact v1"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="contact v1" />
              {/* prevent spam from bots */}
              <div hidden>
                <input name="bot-field" />
              </div>

              <input 
                type="text" 
                name="name" 
                placeholder="NOMBRE *"
                onChange={updateInput}
                value={formData.name || ''}
                required />
              <input 
                type="email" 
                name="email" 
                placeholder="EMAIL *" 
                onChange={updateInput}
                value={formData.email || ''}
                required />
              <div className={recaptcha}>
                <ReCAPTCHA
                  ref={captcha}
                  sitekey="6LcDL_YaAAAAACDTI-TxYtUh9NUtjitzxrrSDIce"
                  onChange={onChange}
                />
              </div>
              { validCaptcha === false && 
                <p style={{color: 'red'}}> 
                  Por favor completa tus datos y el Captcha para continuar
                </p>
              }
              <button type="submit" className="button">
                Enviar
              </button>
            </form>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
export const query = graphql`
  query NewsletterPage {
    titleImage: file(relativePath: { eq: "featured/title-1.png" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`
